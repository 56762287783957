import React from "react"
import { graphql } from "gatsby"
import * as Markdown from "react-markdown"
import styled from "styled-components"
import moment from "moment"
import SEO from "../components/seo"
import fb from "../images/icons/fb.png"
import twitter from "../images/icons/twitter.png"

export const pageQuery = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      slug
      body {
        body
      }
      heroImage {
        file {
          url
        }
      }
      publishDate
      header {
        header
      }
      tags
    }
  }
`

const BlogContent = ({ data }) => {
  const {
    title,
    body,
    heroImage,
    tags,
    slug,
    publishDate,
    header,
  } = data.contentfulBlogPost

  return (
    <Page>
      <SEO title={`Blog | ${title}`} />
      <div className="blog-container">
        <div className="test">
          <div className="hero-image">
            <img className="hero" src={heroImage.file.url} />
          </div>
          <div className="blurb">
            <div className="social">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.FRONTEND_URL}/${slug}`}
                target="_blank"
              >
                <span
                  className="socialRing"
                  style={{
                    display: "flex",
                    marginTop: "7%",
                    marginBottom: "16px",
                  }}
                >
                  <img className="fb" src={fb} />
                </span>
              </a>
              <a
                href={`https://twitter.com/home?status${process.env.FRONTEND_URL}/${slug}`}
                target="_blank"
              >
                <span
                  className="socialRing"
                  style={{
                    display: "flex",
                    marginTop: "7%",
                  }}
                >
                  <img className="twitter" src={twitter} />
                </span>
              </a>
            </div>
            <div className="text-content">
              <div className="published">
                {moment(publishDate).format("D MMMM YYYY")}
              </div>
              <div className="main-title">{title}</div>
              {header && header.header && (
                <div className="header">{header.header}</div>
              )}
              <Markdown className="blog-body" source={body.body} />
            </div>{" "}
          </div>

          <div
            style={{
              maxWidth: "992px",
              margin: "0px auto",
              marginTop: "30px",
              paddingBottom: "30px",
            }}
          >
            <NewsLetter>
              <NewsLetterHeading>Never Miss a deal again!</NewsLetterHeading>
              <NewsLetterHeading
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                Enter your email address to receive exclusive offers, travel
                inspiration and trending destinations delivered straight to your
                inbox.
              </NewsLetterHeading>
              <br />
              <form
                target="_blank"
                action="https://app.getresponse.com/add_subscriber.html"
                method="POST"
                accept-charset="utf-8"
              >
                <ButtonContainer>
                  <EmailInput
                    placeholder="Enter your email address"
                    name="email"
                  />
                  <input type="hidden" name="campaign_token" value="B9bgN" />
                  <input type="hidden" name="start_day" value="0" />
                  <NewButton style={styles.subscribeButton}>
                    Subscribe
                  </NewButton>
                </ButtonContainer>
              </form>
            </NewsLetter>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default BlogContent

const Page = styled.div`
  color: #373f45;
  #content {
    max-width: 1170px;
    margin: 0 auto;
  }

  .blog-container {
    width: 100%;
  }

  .hero-image {
    margin: 0;
    width: 100%;
    max-width: 100%;
    max-height: 70vh;
  }

  h1 {
    text-align: left;
    font-size: 2.5rem;
    margin-bottom: 20px;
    line-height: 1.1;
  }
  .test {
    width: 100%;
    background: white;
  }

  .hero-image {
    width: 100%;
    margin: 0 auto;
    top: 60px;

    .hero {
      width: 100%;
      object-fit: cover;
      height: 500px;
      top: 0;
      z-index: -1;
    }
  }

  h2 {
    font-size: 1.4rem !important;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.08em;
    margin-bottom: 20px;
    font-family: ProximaNova;
    font-size: 18px;
    font-weight: normal;

    font-style: normal;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
  }

  a {
    color: #f66415;
    text-decoration: none;
  }

  li {
    margin-bottom: 20px;
    line-height: 1.54;
    list-style-position: outside;
  }

  .media-content {
    max-width: 1170px;
    margin: 0 auto;

    img {
      width: 140%;
      margin: 2em auto 2em -20%;
    }
  }

  @media only screen and (max-width: 813px) {
    .hero-image {
      height: auto;
    }
    .media-content {
      img {
        width: 100% !important;
        margin: 2em auto !important;
      }
    }
  }
  min-height: calc(100vh - 65px);
  color: #333;

  .socialRing {
    width: 32px;
    height: 32px;
    border: solid 1px rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    .fb {
      width: 8px;
      height: 15px;
      margin-left: 35%;
      margin-top: 27%;
    }
    .twitter {
      width: 15px;
      height: 12px;
      margin-left: 27%;
      margin-top: 34%;
    }
  }

  .outer {
    margin-bottom: 1%;
  }

  .container {
    max-width: 920px;
    margin: 0 auto;
  }
  .destination-flights {
    width: 100%;
    margin-top: -2em;
    max-width: none;
  }
  .heading {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.7em;
    margin-bottom: 1.2rem;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 12px;
  }

  li {
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-family: ProximaNova, Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #414042;
  }

  p,
  a,
  li {
    font-size: 1em;
  }

  .flights {
    background-color: #f9f9f9;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .blurb {
    text-align: center;
    max-width: 720px;
    margin: 30px auto;
    font-size: 25px;
    font-weight: 200;
    line-height: 1.6666;
    margin-bottom: 0;
    display: flex;

    .social {
      margin-left: -7%;
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
    }

    .popular-destination {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flightsHeading {
      font-weight: 600;
      font-family: ProximaNova;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 1%;
      padding-right: 68px;
      text-align: left !important;
    }

    .text-content {
      .blog-body {
        font-family: "ProximaNova";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: 0.9px;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }
      .blog-body p img {
        width: 130%;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: -15%;
      }
      blog-body p {
        margin-bottom: 20px;
      }
      .published {
        font-family: ProximaNova;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: 0.9px;
        text-align: left;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.6);
      }
      .main-title {
        font-family: ProximaNova;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.31;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 40px;
      }
      .header {
        font-family: ProximaNova;
        font-size: 26px;
        line-height: 1.46;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: 577px) and (max-width: 1200px) {
    .blurb {
      flex-direction: column;
      .social {
        display: flex;
        flex-direction: row !important;
        width: 100%;
        margin-bottom: 12px;
        margin-left: 0;
        position: relative;
        a {
          margin-right: 16px;
        }
      }
      .text-content .blog-body p img {
        width: 100%;
        margin: 30px auto;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .topMargin {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .hero {
      height: 200px;
    }
    .blurb {
      padding: 0 1rem;
      width: 100%;
      max-width: none;
      text-align: center !important;
      display: flex;
      flex-direction: column;

      .social {
        display: flex;
        flex-direction: row !important;
        width: 100%;
        margin-bottom: 12px;
        margin-left: 0;
        position: relative;
        a {
          margin-right: 16px;
        }
      }

      .social a {
        padding-right: 16px;
      }
      .flightsHeading {
        font-size: 18px;
      }

      h3 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }

      .text-content {
        width: 100%;
        .main-title {
          font-size: 26px;
        }
        .header {
          font-size: 22px;
        }

        .blog-body {
          font-size: 18px;
          p img {
            width: 100%;
            margin: 30px auto;
          }
        }
      }
    }
  }

  .spacerBT {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .topMargin {
    margin-left: -40px;
    margin-right: 25px;
    margin-top: 5%;
  }

  .flights-hotels {
    h2 {
      &:nth-child(3) {
        margin-top: 2.5rem;
      }
    }

    h3 {
      margin-top: 2.5rem;
      text-align: center;
    }
  }

  .mustList {
    font-family: ProximaNova;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    list-style: none;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 2%;
  }

  ol {
    list-style-position: inside;
    margin-bottom: 2rem;

    li {
      margin-bottom: 1.2rem;
    }
  }

  .travel-agent-cta {
    margin: 3em auto 4em auto;
    text-align: center;
  }

  li.blockBullet::before {
    content: "\\2610";
    margin-left: -40px;
    margin-right: 25px;
  }

  .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    position: absolute;
    margin-left: -50px;
  }
  .must-see {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    .destination-flights {
      margin-top: 0;
    }
    .must-see {
      flex-direction: column;
      margin-left: 35px;
    }
  }
`

const NewsLetter = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 32px;
  width: 100%;
  text-align: left;
`

const NewsLetterHeading = styled.div`
  align: left;
  font-family: ProximaNova;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const EmailInput = styled.input`
  padding-left: 10px;
  margin-right: 32px;
  margin-bottom: 10px;
  width: 72%;
  height: 48px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  backgroundcolor: #ffffff;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  @media only screen and (max-width: 1070px) {
    width: 65%;
    margin-right: 32px;
  }
  @media only screen and (max-width: 805px) {
    width: 55%;
    margin-right: 32px;
  }
  @media only screen and (max-width: 660px) {
    width: 100%;
    margin-right: 0px;
    font-size: 13px;
  }
`
export const NewButton = styled.button`
  height: 50px;
  width: 250px;
  background: orange;
  border: none;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* z-index: 9; */

  &:hover {
    cursor: pointer;
  }
`

const styles = {
  subscribeButton: {
    height: "48px",
    width: "200px",
    backgroundColor: "#ef6c00",
    fontFamily: "ProximaNova",
    fontSize: "18px",
    fontWeight: 600,
    borderRadius: "8px",
  },
}
